body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(126, 9, 9); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full_layout {
  min-height: 100vh;
  height: 100%;
}


.page_name {
  margin-left: 20px;
}

.button_links {
  margin-left: auto;
}

.main_layout {
  display: flex;
  flex-direction: column;
  /* min-width: 600px; */

  /*width: 1300px; */
}

.bottom_section {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content {
  width: 100%;
  min-height: 500px;
  background-color: white;
  margin: 5px;
}

.right_panel {
  width: 200px;
  color: white;
}
