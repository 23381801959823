
.load_button,
#upload_file_label
 {
	box-shadow: 0px 1px 3px -3px #cf866c;
	background:linear-gradient(to bottom, rgb(115,117,132) 5%, rgb(55,70,132) 100%);
	background-color:rgb(55,70,132);
	border:1px solid rgb(55,70,132);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:12px 24px;
	text-decoration:none;
    text-shadow:1px 1px 0px #854629;
    margin: 10px;
}
.load_button:hover,
#upload_file_label:hover {
	background:linear-gradient(to bottom, #bc3315 5%, #d0451b 100%);
	background-color:#bc3315;
}
.load_button:active,
#upload_file_label:active {
	position:relative;
	top:1px;
}

.load_button:disabled {
    background-color: gray;
}

#upload_file {
    display: none;
}